/**
 * About component displaying detailed information about the Solana Jupiter DCA Order Viewer.
 * 
 * This component is styled to match the dark theme of the application and is centered
 * within the layout for consistency with other components like the DCA Viewer.
 * 
 * @component
 */
import React from 'react';
import { Typography, Card, Space, Button, Row, Col } from 'antd';
import { RocketOutlined, LineChartOutlined } from '@ant-design/icons';
import './About.css';

const { Title, Paragraph, Text } = Typography;

const About: React.FC = () => {
  return (
    <div className="about-container">
      {/* Separate Header Card */}
      <Card className="header-card">
        <Typography.Title level={2} className="about-title">
          <img 
            src="/cosmic_logo.png" 
            alt="Cosmic Markets Logo" 
            style={{ 
              height: '40px', 
              marginRight: '12px', 
              verticalAlign: 'middle' 
            }} 
          />
          About Cosmic Markets
        </Typography.Title>
      </Card>

      <Card className="about-card" bordered={false} style={{ marginTop: '16px' }}>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          {/* Introduction Section */}
          <Card className="inner-card" size="small">
            <Space align="start">
              <LineChartOutlined className="section-icon" />
              <div>
                <Title level={3}>Who We Are</Title>
                <Paragraph>
                  Cosmic Markets is a Solana DeFi market data provider delivering comprehensive analytics, historical datasets, and APIs. We empower traders and investors with actionable insights from all decentralized exchanges (DEXes) on Solana, offering exclusive metrics and research to inform trading strategies.
                </Paragraph>
                <Paragraph>
                  Our team is led by <a href="https://x.com/skynetcap" target="_blank" rel="noopener noreferrer">Michael Morrell</a>, a DeFi software engineer and <a href="https://github.com/skynetcap" target="_blank" rel="noopener noreferrer">open source contributor</a>. Interested in joining the team? Email us at contact@cosmic.markets.
                </Paragraph>
              </div>
            </Space>
          </Card>

          {/* Our Product Section */}
          <Card className="inner-card" size="small">
            <Space align="start">
              <RocketOutlined className="section-icon" />
              <div>
                <Title level={3}>Our Product</Title>
                <Paragraph>
                  Our flagship product offers real-time crypto market data aggregated from various DEXes on Solana. We provide bespoke analytics tailored to your trading strategies and fully customizable dashboards to visualize market trends, liquidity pools, and trading volumes.
                </Paragraph>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Text strong>Key Features:</Text>
                  <ul>
                    <li>Comprehensive data from all Solana DEXes</li>
                    <li>Customizable analytics for DeFi trading strategies</li>
                    <li>Interactive dashboards for real-time market visualization</li>
                    <li>Automated alerts for market movements and opportunities</li>
                  </ul>
                </Space>
              </div>
            </Space>
          </Card>

          {/* Future Plans Section */}
          <Card className="inner-card" size="small">
            <Space align="start">
              <RocketOutlined className="section-icon" />
              <div>
                <Title level={3}>Future Plans</Title>
                <Paragraph>
                  We're committed to expanding our DEX coverage on Solana, with plans to integrate every new automated market maker and orderbook-based exchange. Our goal is to provide the most comprehensive trading data and analytics platform for the Solana ecosystem.
                </Paragraph>
              </div>
            </Space>
          </Card>

          {/* CTA Section - Centered using Row/Col */}
          <Row justify="center">
            <Col>
              <Space direction="vertical" align="center">
                <Text>Join us on our journey to revolutionize DeFi market analytics.</Text>
                <Button 
                  type="primary" 
                  icon={<RocketOutlined />}
                  href="mailto:contact@cosmic.markets"
                  target="_blank"
                  size="large"
                >
                  Contact Us
                </Button>
              </Space>
            </Col>
          </Row>
        </Space>
      </Card>
    </div>
  );
};

export default About;
