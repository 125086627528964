import React, { useState, useCallback, useMemo } from 'react';
import { Table, Input, Select, Row, Col, Typography, Card, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { DCAOrder, TableFilters } from './types';
import './OrdersTable.css';

const { Option } = Select;

interface OrdersTableProps {
  title: string;
  getColumns: (isNextDay: boolean) => ColumnsType<DCAOrder>;
  dataSource: DCAOrder[];
  isLoading: boolean;
  filterKey: 'Next Day' | 'All';
}

const OrdersTable: React.FC<OrdersTableProps> = ({
  title,
  getColumns,
  dataSource,
  isLoading,
  filterKey,
}) => {
  const [filters, setFilters] = useState<TableFilters>({
    search: '',
    tokenMint: '',
  });

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20', '50'],
  });

  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  const handleTableChange = useCallback(
    (newPagination: TablePaginationConfig) => {
      setPagination(newPagination);
    },
    []
  );

  const handleExpand = useCallback(
    (expanded: boolean, record: DCAOrder) => {
      if (expanded) {
        setExpandedRowKeys((prev) => [...prev, record.idx]);
      } else {
        setExpandedRowKeys((prev) => prev.filter((key) => key !== record.idx));
      }
    },
    []
  );

  /**
   * Filters orders based on the provided criteria and filterKey.
   */
  const getFilteredOrders = useMemo(() => {
    const now = new Date();
    let relevantOrders = dataSource.filter(order => {
      const executeTime = new Date(order.nextCycleAt);
      if (filterKey === 'Next Day') {
        return executeTime > now && executeTime <= new Date(now.getTime() + 24 * 60 * 60 * 1000);
      } else {
        return true;
      }
    });

    if (filters.tokenMint) {
      relevantOrders = relevantOrders.filter(order =>
        order.inputMint === filters.tokenMint || order.outputMint === filters.tokenMint
      );
    }

    if (filters.search) {
      const searchLower = filters.search.toLowerCase();
      relevantOrders = relevantOrders.filter(order =>
        order.user.toLowerCase().includes(searchLower) ||
        order.inputMint.toLowerCase().includes(searchLower) ||
        order.outputMint.toLowerCase().includes(searchLower)
      );
    }

    return relevantOrders;
  }, [dataSource, filters, filterKey]);

  const getTooltipText = (title: string) => {
    if (title === 'Next 24 Hours') {
      return "Shows DCA orders scheduled to execute within the next 24 hours. These orders represent immediate market impact and can be used to anticipate short-term price movements and liquidity demands.";
    }
    return "Displays all active DCA orders regardless of execution time. This view helps understand longer-term market trends and overall capital deployment patterns across different tokens.";
  };

  return (
    <Card
      className="orders-table-container"
      title={
        <Row gutter={[16, 16]} align="middle">
          <Col flex="auto">
            <div className="orders-title">
              <img
                src="/jupiter.png"
                alt="Jupiter Logo"
                className="header-logo"
                draggable="false"
              />
              <Tooltip title={getTooltipText(title)}>
                <Typography.Title level={4} style={{ margin: 0 }}>
                  {title}
                </Typography.Title>
              </Tooltip>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Input
              id={`search-${filterKey.toLowerCase()}-input`}   // Added id
              name={`search-${filterKey.toLowerCase()}`}      // Added name
              placeholder="Search..."
              onChange={(e) =>
                setFilters((prev: any) => ({ ...prev, search: e.target.value }))
              }
              allowClear
              size="small"
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Select
              id={`filter-${filterKey.toLowerCase()}-select`} // Added id
              placeholder="Filter by token"
              onChange={(value) =>
                setFilters((prev: any) => ({ ...prev, tokenMint: value }))
              }
              style={{ width: '100%' }}
              allowClear
              size="small"
            >
              <Option value="">All Tokens</Option>
              <Option value="USDC">USDC</Option>
              <Option value="USDT">USDT</Option>
              <Option value="DAI">DAI</Option>
            </Select>
          </Col>
        </Row>
      }
    >
      <Table
        columns={getColumns(filterKey === 'Next Day')}
        dataSource={getFilteredOrders}
        pagination={pagination}
        onChange={handleTableChange}
        rowKey="idx"
        scroll={{ x: 'max-content' }}
        size="small"
        expandable={{
          expandedRowRender,
          expandRowByClick: true,
          expandedRowKeys: expandedRowKeys,
          onExpand: handleExpand,
          rowExpandable: () => true,
        }}
        loading={isLoading}
        className="orders-table"
      />
    </Card>
  );
};

// Expanded row render function
const expandedRowRender = (record: DCAOrder) => {
  const completedPercentage = Math.min(
    Number(((record.inUsed / record.inDeposited) * 100).toFixed(2)),
    100
  );
  const percentRemaining = (100 - completedPercentage).toFixed(2);
  return (
    <div className="expanded-row-content">
      <Typography.Text>
        <strong>% Remaining:</strong> {percentRemaining}%
      </Typography.Text>
    </div>
  );
};

export default OrdersTable;
