import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { ConfigProvider, theme, Row, Col, Typography, Tooltip as AntTooltip, Progress } from 'antd';
import './JupiterDCAViewer.css';
import HeaderCard from './HeaderCard';
import DcaChart from './DcaChart';
import AggregateTable from './AggregateTable';
import OrdersTable from './OrdersTable';
import AboutContent from './AboutContent';
import { DCAOrder, AggregateData } from './types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title as ChartTitle,
  Tooltip as ChartTooltip,  // Renamed this import
  Legend,
  Colors,
} from 'chart.js';
import type { ColumnsType } from 'antd/es/table';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartTitle,
  ChartTooltip,  // Updated registration
  Legend,
  Colors
);

const truncateAddress = (address: string): string => {
  if (address.length <= 10) return address;
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export default function JupiterDCAViewer() {
  const [orders, setOrders] = useState<DCAOrder[]>([]);
  const [aggregates, setAggregates] = useState<AggregateData[]>([]); // New state for aggregates
  const [tokenMints, setTokenMints] = useState<string[]>([]);

  const hasFetched = useRef(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  /**
   * Fetches mock DCA orders based on the provided filters.
   */
  const fetchDCAOrders = async (filters: any): Promise<DCAOrder[]> => {
    // Helper functions...
    const randomInRange = (min: number, max: number) =>
      Math.floor(Math.random() * (max - min + 1)) + min;

    const randomExponential = (min: number, max: number) => {
      const exp = Math.random() * Math.log(max / min);
      return Math.floor(Math.exp(exp) * min);
    };

    // Known Solana tokens for mock data
    const knownTokens = [
      'USDT', 'USDC', 'DAI', 'SOL', 'ETH', 'BTC', 'SRM', 'RAY', 'FTT', 'MSOL',
      'COPE', 'FTM', 'AVAX', 'ADA', 'DOT', 'MANA', 'SAND', 'GRT', 'AAVE', 'LINK',
      'UNI', 'MKR', 'SNX', 'PEPE', 'COPE', 'FLARE', 'LUNA', 'BNB', 'XRP', 'DOGE',
      'SHIB', 'MATIC', 'LDO', 'BADGER', 'CRV'
    ];

    // Increase the number of mock orders and diversify tokens
    const mockOrders: DCAOrder[] = Array.from({ length: 200 }, (_, index) => {
      const baseDeposit = randomExponential(100, 500000);
      const cycleAmount = randomExponential(100, 50000);
      
      // Modify the inUsed calculation to get a wider range of progress values
      const progressPercentage = Math.random(); // 0 to 1
      const skewedProgress = Math.pow(progressPercentage, 1.5); // Skew towards lower values
      const inUsed = baseDeposit * skewedProgress;

      return {
        user: `UserPublicKey_${index}`,
        inputMint: knownTokens[randomInRange(0, knownTokens.length - 1)],
        outputMint: knownTokens[randomInRange(0, knownTokens.length - 1)],
        idx: index,
        nextCycleAt: Date.now() + Math.floor((index * 72) / 200) * 3600 * 1000, // Spread over 72 hours
        inDeposited: baseDeposit,
        inWithdrawn: baseDeposit * (randomInRange(5, 20) / 100), // 5-20% of deposit
        outWithdrawn: baseDeposit * (randomInRange(5, 15) / 100), // 5-15% of deposit
        inUsed: inUsed,
        outReceived: baseDeposit * (randomInRange(50, 85) / 100), // 50-85% of deposit
        inAmountPerCycle: cycleAmount,
        cycleFrequency: [1, 4, 8, 12, 24][randomInRange(0, 4)], // Various frequencies
        nextCycleAmountLeft: baseDeposit * (randomInRange(10, 40) / 100), // 10-40% remaining
        inAccount: `InAccountPublicKey_${index}`,
        outAccount: `OutAccountPublicKey_${index}`,
        minOutAmount: cycleAmount * 0.8,
        maxOutAmount: cycleAmount * 1.2,
        keeperInBalanceBeforeBorrow: baseDeposit * 1.5,
        dcaOutBalanceBeforeSwap: baseDeposit * 0.8,
        createdAt: Date.now() - randomInRange(1, 30) * 86400 * 1000, // Created 1-30 days ago
        bump: 0,
      };
    });

    return mockOrders;
  };

  /**
   * Fetches DCA orders and sets the state.
   */
  const fetchOrders = useCallback(async () => {
    setIsLoading(true);
    const fetchedOrders = await fetchDCAOrders({});
    setOrders(fetchedOrders);
    setIsLoading(false);
  }, []);

  /**
   * Fetches aggregate data from the API.
   */
  const fetchAggregates = useCallback(async () => {
    try {
      const response = await fetch('https://api.cosmic.markets/api/dca/aggregates');
      if (!response.ok) {
        throw new Error(`Error fetching aggregates: ${response.statusText}`);
      }
      const data = await response.json();
      console.log('Raw Json Data:', data);

      // Directly set aggregates if data matches AggregateData[]
      const mappedData: AggregateData[] = data.map((item: any) => ({
        token: item.token || 'Unknown Token',
        buysUsd: Number(item.buysUsd) || 0,
        sellsUsd: Number(item.sellsUsd) || 0,
        totalUsd: Number(item.totalUsd) || 0,
      }));

      setAggregates(mappedData);
      console.log('Aggregates Data Set:', mappedData);  
    } catch (error) {
      console.error('Fetch Aggregates Error:', error);
      setError((error as Error).message);
    }
  }, []);

  useEffect(() => {
    if (!hasFetched.current) {
      fetchOrders();
      fetchAggregates(); // Initial fetch for aggregates
      hasFetched.current = true;
    }

    // Set up polling for aggregates every 4 seconds
    const intervalId = setInterval(() => {
      fetchAggregates();
    }, 4000); // 4000ms = 4 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [fetchOrders, fetchAggregates]);

  useEffect(() => {
    const allMints = Array.from(new Set([...orders.map(order => order.inputMint), ...orders.map(order => order.outputMint)]));
    allMints.sort();
    setTokenMints(allMints);
  }, [orders]);

  /**
   * Defines the columns for the Ant Design Table tailored for DeFi power users.
   */
  const getColumns = useCallback((isNextDay: boolean): ColumnsType<DCAOrder> => {
    return [
      {
        title: 'Input',
        dataIndex: 'inputMint',
        key: 'inputMint',
        sorter: (a: DCAOrder, b: DCAOrder) => a.inputMint.localeCompare(b.inputMint),
        render: (text: string) => (
          <span>
            {text}
            {['USDC', 'USDT', 'DAI'].includes(text) && (
              <span style={{ color: '#4caf50', marginLeft: '4px' }}>(Stable)</span>
            )}
          </span>
        ),
      },
      {
        title: 'Output',
        dataIndex: 'outputMint',
        key: 'outputMint',
        sorter: (a: DCAOrder, b: DCAOrder) => a.outputMint.localeCompare(b.outputMint),
      },
      {
        title: 'Total ($)',
        key: 'totalAmountUSD',
        sorter: (a: DCAOrder, b: DCAOrder) => a.outReceived - b.outReceived,
        render: (_text, record) => {
          const totalUSD = record.outReceived * 1.0; // Mock conversion rate
          return `$${totalUSD.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        },
        align: 'right',
      },
      {
        title: '$ / Minute',
        key: 'usdSpentPerMinute',
        sorter: (a: DCAOrder, b: DCAOrder) => {
          const aSpentPerMinute = a.inUsed / (a.cycleFrequency * 60);
          const bSpentPerMinute = b.inUsed / (b.cycleFrequency * 60);
          return aSpentPerMinute - bSpentPerMinute;
        },
        defaultSortOrder: 'descend', // Set default sort order
        render: (_text, record) => {
          const usdSpentPerCycle = record.inUsed * 1.0; // Mock conversion rate
          const usdSpentPerMinute = usdSpentPerCycle / (record.cycleFrequency * 60);
          return `$${usdSpentPerMinute.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        },
        align: 'right',
      },
      {
        title: 'Progress',
        key: 'progress',
        sorter: (a: DCAOrder, b: DCAOrder) => {
          const progressA = (a.inUsed / a.inDeposited) * 100;
          const progressB = (b.inUsed / b.inDeposited) * 100;
          return progressA - progressB;
        },
        render: (_text, record) => {
          const completedPercentage = Math.min(
            Number(((record.inUsed / record.inDeposited) * 100).toFixed(0)),
            100
          );

          const getProgressColor = (percent: number) => {
            if (percent > 90) return '#faad14';
            return '#52c41a';
          };

          return (
            <AntTooltip title={`${completedPercentage}% Complete`}>
              <div className="progress-row">
                <Progress 
                  percent={completedPercentage} 
                  size="small" 
                  strokeColor={getProgressColor(completedPercentage)}
                  showInfo={false}
                  className="custom-progress"
                />
                <Typography.Text className="progress-text">{completedPercentage}%</Typography.Text>
              </div>
            </AntTooltip>
          );
        },
        align: 'center',
        width: 100,
      },
    ];
  }, []);

  /**
   * Chart Data and Options
   */
  const chartData = useMemo(() => {
    // Sort aggregates by total volume and take top 10
    const top10Aggregates = [...aggregates]
      .sort((a, b) => b.totalUsd - a.totalUsd)
      .slice(0, 10);

    const labels = top10Aggregates.map(agg => truncateAddress(agg.token));
    const data = top10Aggregates.map(agg => agg.totalUsd);

    return {
      labels,
      datasets: [
        {
          label: 'Total Volume',
          data,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        },
      ],
    };
  }, [aggregates]);

  const chartOptions = useMemo(() => ({
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => `$${context.parsed.y.toLocaleString()}`
        }
      }
    },
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: 90,
          minRotation: 45,
          color: '#e0e0e0',
          font: {
            size: 14, // Match aggregate header font size
          }
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: '#e0e0e0',
          callback: function(value: number | string) { // Modified this line
            return `$${Number(value) / 1000}k`;
          },
          font: {
            size: 14, // Match aggregate header font size
          }
        },
        grid: {
          color: '#333',
        },
      },
    },
  }), []);

  /**
   * Define columns for the Aggregate Table
   */
  const getAggregateColumns = useCallback((): ColumnsType<AggregateData> => [
    {
      title: 'Token',
      dataIndex: 'token',
      key: 'token',
      sorter: (a: AggregateData, b: AggregateData) => a.token.localeCompare(b.token),
      render: (text: string) => <Typography.Text>{truncateAddress(text)}</Typography.Text>,
    },
    {
      title: 'Total',
      dataIndex: 'totalUsd',
      key: 'totalUsd', // This key should match the flash state key: `${agg.token}-totalUsd`
      sorter: (a: AggregateData, b: AggregateData) => (a.totalUsd || 0) - (b.totalUsd || 0),
      render: (value) => {
        const num = Number(value) || 0;
        return `$${num.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      },
      align: 'right',
      defaultSortOrder: 'descend',
    },
    {
      title: 'Buys',
      dataIndex: 'buysUsd',
      key: 'buysUsd', // This key should match the flash state key: `${agg.token}-buysUsd`
      sorter: (a: AggregateData, b: AggregateData) => (a.buysUsd || 0) - (b.buysUsd || 0),
      render: (value) => {
        const num = Number(value) || 0;
        return `$${num.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      },
      align: 'right',
    },
    {
      title: 'Sells',
      dataIndex: 'sellsUsd',
      key: 'sellsUsd', // This key should match the flash state key: `${agg.token}-sellsUsd`
      sorter: (a: AggregateData, b: AggregateData) => (a.sellsUsd || 0) - (b.sellsUsd || 0),
      render: (value) => {
        const num = Number(value) || 0;
        return `$${num.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      },
      align: 'right',
    },
  ], []);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: '#4caf50',
          colorBgContainer: '#1e1e1e',
          colorText: '#e0e0e0',
        },
      }}
    >
      <div className="dca-viewer-container">
        {/* Header */}
        <HeaderCard />

        {/* Charts and Aggregate Table */}
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <DcaChart data={chartData} options={chartOptions} />
          </Col>
          <Col xs={24} lg={12}>
            <AggregateTable
              aggregates={aggregates}
              getAggregateColumns={getAggregateColumns}
              isLoading={isLoading}
              error={error}
            />
          </Col>
        </Row>

        {/* Orders Tables */}
        <Row gutter={[16, 16]} style={{ marginTop: '8px' }}>
          <Col xs={24} lg={12}>
            <OrdersTable
              title="Next 24 Hours"
              getColumns={getColumns}
              dataSource={orders}
              isLoading={isLoading}
              filterKey="Next Day" // Added filterKey
            />
          </Col>
          <Col xs={24} lg={12}>
            <OrdersTable
              title="All Active"
              getColumns={getColumns}
              dataSource={orders}
              isLoading={isLoading}
              filterKey="All" // Added filterKey
            />
          </Col>
        </Row>

        {/* About Section */}
        <AboutContent />
      </div>
    </ConfigProvider>
  );
}
