import React, { useState, useEffect, useRef } from 'react';
import { Table, Typography, Card, Progress } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { AggregateData } from './types';
import './AggregateTable.css';

interface AggregateTableProps {
  aggregates: AggregateData[];
  getAggregateColumns: () => ColumnsType<AggregateData>;
  isLoading: boolean;
  error: string | null;
}

const AggregateTable: React.FC<AggregateTableProps> = ({
  aggregates,
  getAggregateColumns,
  isLoading,
  error,
}) => {
  const prevAggregates = useRef<AggregateData[]>([]);
  const [flashStates, setFlashStates] = useState<Record<string, 'increase' | 'decrease' | null>>({});
  const [progress, setProgress] = useState<number>(0);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 5,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20'],
  });

  useEffect(() => {
    const newFlashStates: Record<string, 'increase' | 'decrease' | null> = {};

    aggregates.forEach((agg) => {
      const prev = prevAggregates.current.find(p => p.token === agg.token);
      if (prev) {
        let totalChange: 'increase' | 'decrease' | null = null;

        // Check for changes in Total USD
        if (agg.totalUsd > prev.totalUsd) {
          newFlashStates[`${agg.token}-totalUsd`] = 'increase';
          totalChange = 'increase';
        } else if (agg.totalUsd < prev.totalUsd) {
          newFlashStates[`${agg.token}-totalUsd`] = 'decrease';
          totalChange = 'decrease';
        }

        // If Total USD changed, set flash state for Token cell
        if (totalChange) {
          newFlashStates[`${agg.token}-token`] = totalChange;
        }

        // Check for changes in Buys USD
        if (agg.buysUsd > prev.buysUsd) {
          newFlashStates[`${agg.token}-buysUsd`] = 'increase';
        } else if (agg.buysUsd < prev.buysUsd) {
          newFlashStates[`${agg.token}-buysUsd`] = 'decrease';
        }

        // Check for changes in Sells USD
        if (agg.sellsUsd > prev.sellsUsd) {
          newFlashStates[`${agg.token}-sellsUsd`] = 'increase';
        } else if (agg.sellsUsd < prev.sellsUsd) {
          newFlashStates[`${agg.token}-sellsUsd`] = 'decrease';
        }
      }
    });

    // Update flash states
    setFlashStates(newFlashStates);
    // Update previous aggregates
    prevAggregates.current = aggregates;

    // Reset progress
    setProgress(0);

    // Progress bar settings for 3.9 seconds
    const totalDuration = 3900; // 3.9 seconds
    const interval = 50; // 50ms
    const steps = totalDuration / interval; // 78 steps
    const increment = 100 / steps; // ~1.282%

    const progressInterval = setInterval(() => {
      setProgress(prev => {
        const nextProgress = prev + increment;
        return nextProgress > 100 ? 100 : nextProgress;
      });
    }, interval);

    // Clear flash states after animation duration
    const flashTimer = setTimeout(() => {
      setFlashStates({});
    }, 1500); // Duration matches the CSS animation duration

    return () => {
      clearInterval(progressInterval);
      clearTimeout(flashTimer);
    };
  }, [aggregates]);

  const handleTableChange = (newPagination: TablePaginationConfig) => {
    setPagination(newPagination);
  };

  const getColumnWithFlash = (columns: ColumnsType<AggregateData>): ColumnsType<AggregateData> => {
    return columns.map((col) => {
      const newCol = {
        ...col,
        onCell: (record: AggregateData) => {
          const key = `${record.token}-${col.key}`;
          const flashState = flashStates[key];

          if (col.key === 'token') {
            return {
              className: flashState ? `flash-${flashState}` : '',
              onClick: () => {
                window.open(`https://solscan.io/account/${record.token}`, '_blank');
              },
            };
          }

          return {
            className: flashState ? `flash-${flashState}` : '',
          };
        },
        render: (value: any, record: AggregateData, index: number) => {
          if (col.key === 'token') {
            const originalRender = col.render ? col.render(value, record, index) : value;
            return (
              <a
                href={`https://solscan.io/account/${record.token}`}
                target="_blank"
                rel="noopener noreferrer"
                className="token-link"
              >
                {originalRender}
              </a>
            );
          } else {
            return col.render ? col.render(value, record, index) : value;
          }
        },
      };

      return newCol;
    });
  };

  return (
    <Card
      className="table-card"
      title={
        <div className="aggregate-table-header">
          <div className="aggregate-title">
            <img
              src="/jupiter.png"
              alt="Jupiter Logo"
              className="header-logo"
              draggable="false"
            />
            <Typography.Title level={4} style={{ margin: 0 }} className="aggregate-header">
              Jupiter DCA Trade Volume by Token
            </Typography.Title>
          </div>
          <Progress
            type="circle"
            percent={progress}
            size={20}
            strokeColor={{
              '0%': '#108ee9',
              '100%': '#87d068',
            }}
            trailColor="#d9d9d9"
            format={() => 'Time until next refresh'} // Remove text from the circle
            aria-label="Time until next refresh"
          />
        </div>
      }
    >
      {error ? (
        <Typography.Text type="danger">{error}</Typography.Text>
      ) : (
        <Table
          columns={getColumnWithFlash(getAggregateColumns())}
          dataSource={aggregates}
          pagination={pagination}
          onChange={handleTableChange}
          rowKey={(record) =>
            `${record.token}-${record.buysUsd}-${record.sellsUsd}-${record.totalUsd}`
          }
          size="small"
          bordered
          style={{ backgroundColor: '#1e1e1e' }}
          loading={isLoading}
          scroll={{ x: 'max-content' }}
        />
      )}
    </Card>
  );
};

export default AggregateTable;
