import React from 'react';
import { Typography, Card, Space, Button, Row, Col, List, Tag } from 'antd';
import { DollarCircleOutlined, RocketOutlined, BarChartOutlined, CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import './AboutContent.css';

const { Title, Paragraph } = Typography;

const AboutContent: React.FC = () => {
  return (
    <Card className="about-card" bordered={false}>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {/* What is DCA Section */}
        <Card className="inner-card" size="small">
          <Space align="start">
            <DollarCircleOutlined className="section-icon" />
            <div>
              <Typography.Title level={3}>What is Dollar Cost Averaging (DCA)?</Typography.Title>
              <Typography.Paragraph>
                DCA is a systematic investment strategy that mitigates price volatility risk through 
                periodic, fixed-value acquisitions of assets. Rather than attempting to time market 
                entry points, this approach implements a consistent investment schedule - for instance, 
                allocating $100 to Bitcoin on a weekly basis, irrespective of market conditions. This 
                methodology effectively reduces timing risk while building positions through systematic 
                accumulation.
              </Typography.Paragraph>
            </div>
          </Space>
        </Card>

        {/* Jupiter DCA Section */}
        <Card className="inner-card" size="small">
          <Space align="start">
            <RocketOutlined className="section-icon" />
            <div>
              <Typography.Title level={3}>What is Jupiter DCA?</Typography.Title>
              <Typography.Paragraph>
                Jupiter DCA provides automated order execution infrastructure on Solana's network. 
                The protocol leverages Jupiter's aggregated liquidity pools to optimize trade execution, 
                enabling programmatic implementation of systematic investment strategies with minimal 
                slippage and maximum capital efficiency.
              </Typography.Paragraph>
              <Tag color="blue">Automated Execution</Tag>
              <Tag color="green">Capital Efficient</Tag>
              <Tag color="purple">Order Transparency</Tag>
            </div>
          </Space>
        </Card>

        {/* Pros and Cons Section */}
        <Card className="inner-card">
          <Space align="start">
            <BarChartOutlined className="section-icon" />
            <div style={{ width: '100%' }}>
              <Typography.Title level={3}>Advantages and Limitations of DCA Implementation</Typography.Title>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <List
                    header={
                      <Space>
                        <CheckCircleOutlined style={{ color: '#52c41a' }} />
                        <Typography.Text strong>Advantages</Typography.Text>
                      </Space>
                    }
                    bordered
                    dataSource={[
                      'Eliminates behavioral bias in execution strategy',
                      'Automated implementation reduces operational overhead',
                      'Natural volatility hedge through price averaging mechanics',
                    ]}
                    renderItem={(item) => (
                      <List.Item>
                        <Typography.Text>{item}</Typography.Text>
                      </List.Item>
                    )}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <List
                    header={
                      <Space>
                        <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
                        <Typography.Text strong>Limitations</Typography.Text>
                      </Space>
                    }
                    bordered
                    dataSource={[
                      'Potential opportunity cost in strong directional markets',
                      'Requires strict adherence to implementation parameters',
                      'Maintains full beta exposure to underlying asset',
                    ]}
                    renderItem={(item) => (
                      <List.Item>
                        <Typography.Text>{item}</Typography.Text>
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
            </div>
          </Space>
        </Card>

        {/* Market Intelligence Section */}
        <Card className="inner-card">
          <Space align="start">
            <InfoCircleOutlined className="section-icon" />
            <div>
              <Typography.Title level={3}>Market Intelligence: Tracking DCA Order Flow</Typography.Title>
              <Typography.Paragraph>
                Our platform democratizes access to DCA order data, giving you visibility into significant upcoming market 
                movements. By monitoring when and where large DCA orders are placed, traders can make more informed decisions 
                about their own market entry points. This transparency allows everyone to see the same market signals that 
                were previously only available to a select few, creating a more level playing field for all participants.
              </Typography.Paragraph>
            </div>
          </Space>
        </Card>

        {/* CTA Section */}
        <Row justify="center">
          <Space direction="vertical" align="center">
            <Typography.Text>Ready to start your DCA journey?</Typography.Text>
            <Button 
              type="primary" 
              icon={<ArrowRightOutlined />}
              href="https://jup.ag/dca/"
              target="_blank"
              size="large"
            >
              Get Started with Jupiter DCA
            </Button>
          </Space>
        </Row>
      </Space>
    </Card>
  );
};

export default AboutContent;