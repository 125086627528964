import React, { useState, useEffect, useRef } from 'react';
import { Table, Typography, Card, Input, Space, Row, Col } from 'antd';
import { ColumnsType } from 'antd/es/table';
import './TokenList.css';

const { Text } = Typography;

interface TokenData {
  mint: string;
}

const TokenList: React.FC = () => {
  const [tokens, setTokens] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [searchText, setSearchText] = useState<string>(''); // Added searchText state

  const hasFetched = useRef(false); // Ref to track if fetch has occurred

  const fetchTokens = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://api.cosmic.markets/api/dca/tokens');
      if (!response.ok) {
        throw new Error(`Error fetching tokens: ${response.statusText}`);
      }
      const data: string[] = await response.json();
      setTokens(data);
    } catch (err) {
      console.error(err);
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hasFetched.current) return; // Prevent double fetch
    hasFetched.current = true;
    fetchTokens();
  }, []);

  // Transform and filter tokens based on searchText
  const dataSource: TokenData[] = tokens
    .filter((mint) => mint.toLowerCase().includes(searchText.toLowerCase()))
    .map((mint) => ({ mint }));

  const columns: ColumnsType<TokenData> = [
    {
      title: 'Token Mint',
      dataIndex: 'mint',
      key: 'mint',
      render: (text: string) => (
        <a href={`https://solscan.io/token/${text}`} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
      sorter: (a, b) => a.mint.localeCompare(b.mint),
    },
  ];

  return (
    <Card className="token-list-card" title="Solana Token Database">
      <Space direction="vertical" style={{ width: '100%' }}>
        {/* Search Input */}
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Input
              id="search-tokens-input"          // Added id
              name="searchTokens"               // Added name
              placeholder="Search tokens..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              allowClear
            />
          </Col>
        </Row>

        {/* Token Count */}
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Text strong>
              {dataSource.length} {dataSource.length === 1 ? 'Token' : 'Tokens'}
            </Text>
          </Col>
        </Row>

        {/* Error Message or Table */}
        {error ? (
          <Typography.Text type="danger">{error}</Typography.Text>
        ) : (
          <Table
            columns={columns}
            dataSource={dataSource}
            rowKey="mint"
            loading={loading}
            pagination={{ pageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '20'] }}
            className="token-table"
            scroll={{ x: 'max-content' }}
          />
        )}
      </Space>
    </Card>
  );
};

export default TokenList;
