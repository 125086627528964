import React from 'react';
import { Typography, Card } from 'antd';
import './HeaderCard.css';

const HeaderCard: React.FC = () => {
  return (
    <Card className="header-card">
      <Typography.Title level={2} className="dca-viewer-header">
        <div className="header-content">
          <img 
            src="/jupiter.png" 
            alt="Jupiter Logo" 
            style={{ 
              height: '32px', 
              marginRight: '12px', 
              verticalAlign: 'middle' 
            }} 
          />
          Jupiter Dollar Cost Average (DCA) Orders
        </div>
      </Typography.Title>
      <div style={{ textAlign: 'center' }}>
        <Typography.Text type="secondary" italic style={{ fontSize: '12px' }}>
          Note: This is a demo using mock data for illustrative purposes only.
        </Typography.Text>
      </div>
    </Card>
  );
};

export default HeaderCard;
